import { Contact } from "app/main/apps/contacts/contact.model";

export class TsipouraModel {

    obligee?: Contact;
    boilerOwner?: Contact;
    agm: string;
    boilerCapacity: number;
    startDate: Date;
    endDate: Date;
    workHoursPerDay: number;
    tsipouraCalculationData: TsipouraCalculationDataModel[];
    tsipouraTotals: TsipouraTotalsModel;

    constructor(value?: TsipouraModel) {

        //defaultStartDate is the day after today
        const defaultStartDate = new Date();
        defaultStartDate.setDate(defaultStartDate.getDate() + 1); // Add 1 day to the current date
        defaultStartDate.setHours(8, 0, 0, 0); // Set time to 8:00:00.000

        this.obligee = value?.obligee || null;
        this.boilerOwner = value?.boilerOwner || null;
        this.agm = value?.agm || null;
        this.boilerCapacity = value?.boilerCapacity || 130;
        this.startDate = value?.startDate || defaultStartDate;
        this.endDate = value?.endDate || null;
        this.workHoursPerDay = value?.workHoursPerDay || 12;
        this.tsipouraCalculationData = value?.tsipouraCalculationData || null;
        this.tsipouraTotals = value?.tsipouraTotals || null;
    }


}

export class TsipouraCalculationDataModel {

    producerName: string;
    producerAfm: string;
    alcoholVol: number;
    wineGrapes: number;
    nonWineGrapes: number;
    grapeStems: number;
    lpa: number;
    finalNetWeight: number;
    numberOfBoilers: number;
    hours: number;
    days: number;
    efk: number;
    startDateTime: number;
    endDateTime: number;


    constructor(value?: TsipouraCalculationDataModel) {

        this.producerName = value?.producerName || null;
        this.producerAfm = value?.producerAfm || null;
        this.alcoholVol = value?.alcoholVol || 0.4;
        this.wineGrapes = value?.wineGrapes || null;
        this.nonWineGrapes = value?.nonWineGrapes || null;
        this.grapeStems = value?.grapeStems || null;
        this.lpa = value?.lpa || null;
        this.finalNetWeight = value?.finalNetWeight || null;
        this.numberOfBoilers = value?.numberOfBoilers || null;
        this.hours = value?.hours || null;
        this.days = value?.days || null;
        this.efk = value?.efk || null;
        this.startDateTime = value?.startDateTime || null;
        this.endDateTime = value?.endDateTime || null;
    }

}

export class TsipouraTotalsModel {
    totalWineGrapes: number;
    totalNonWineGrapes: number;
    totalGrapeStems: number;
    totalLpa: number;
    totalFinalNetWeight: number;
    totalNumberOfBoilers: number;
    totalHours: number;
    totalDays: number;
    totalEfk: number

    constructor(value?: TsipouraTotalsModel) {

        this.totalWineGrapes = value?.totalWineGrapes || 0;
        this.totalNonWineGrapes = value?.totalNonWineGrapes || 0;
        this.totalGrapeStems = value?.totalGrapeStems || 0;
        this.totalLpa = value?.totalLpa || 0;
        this.totalFinalNetWeight = value?.totalFinalNetWeight || 0;
        this.totalNumberOfBoilers = value?.totalNumberOfBoilers || 0;
        this.totalHours = value?.totalHours || 0;
        this.totalDays = value?.totalDays || 0;
        this.totalEfk = value?.totalEfk || 0;
    }

}