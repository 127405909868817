<table class="noPrint" [formGroup]="tsipouraForm">
    <colgroup>
        <col style="width: 2%;" />
        <col style="width: 12%;" />
        <col style="width: 5%;" />
        <col style="width: 4%;" />
        <col style="width: 6%;" />
        <col style="width: 6%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 5%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 6%;" />
        <col class="noPrint" style="width: 4%;" />
        <col class="noPrint" style="width: 4%;" />
    </colgroup>
    <thead>
        <tr>
            <th>Α.Α.</th>
            <th>ΠΑΡΑΓΩΓΟΣ</th>
            <th>ΑΦΜ</th>
            <th>ΑΛΚΟΛΙΚΟΣ ΤΙΤΛΟΣ %/vol.</th>
            <th>ΣΤΑΦΥΛΙΑ ΟΙΝΟΠΟΙΗΣΙΜΑ (Kg)</th>
            <th>ΣΤΑΦΥΛΙΑ MH ΟΙΝΟΠΟΙΗΣΙΜΑ (Kg)</th>
            <th>ΣΤΕΜΦΥΛΑ</th>
            <th>Λ.Α. ΑΙΘΥΛΙΚΗΣ ΑΛΚΟΟΛΗΣ</th>
            <th>Λ.Ε. ΤΕΛΙΚΟ ΠΡΟΙΟΝ</th>
            <th>ΚΑΖΑΝΙΕΣ</th>
            <th>ΩΡΕΣ ΕΡΓΑΣΙΑΣ</th>
            <th>ΗΜΕΡΕΣ ΕΡΓΑΣΙΑΣ</th>
            <th>ΦΟΡΟΣ ΟΙΝΟΠΝΕΥΜΑΤΟΣ</th>
            <th class="noPrint">AΙΤΗΣΗ</th>
            <th class="noPrint"></th>
            <!-- ... other headers -->
        </tr>
    </thead>

    <tbody formArrayName="tsipouraCalculationData">
        <tr *ngFor="let item of tsipouraCalculationData.controls; let i = index" [formGroupName]="i">
            <td><input [value]="i+1" readonly tabindex="-1"></td>
            <td><input #firstInput class="excel-input text-bold text-left" formControlName="producerName"></td>
            <td><input class="excel-input text-bold" formControlName="producerAfm" digitOnly maxlength="9"></td>
            <td style="text-align: center;">
                <select style="padding: 3px 10px;" class="excel-input text-bold" formControlName="alcoholVol">
                    <option *ngFor="let item of alcoholVolOptions" [value]="item.value">
                        {{item.desc}} </option>
                </select>
            </td>
            <td><input class="excel-input text-bold" formControlName="wineGrapes" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" (blur)="formatDecimals(item.get('wineGrapes'))"></td>
            <td><input class="excel-input" formControlName="nonWineGrapes" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" (blur)="formatDecimals(item.get('nonWineGrapes'))"></td>
            <td><input class="excel-input text-bold" formControlName="grapeStems" digitOnly [decimal]="true"
                    [numberOfDecimals]="2" (blur)="formatDecimals(item.get('grapeStems'))"></td>
            <td><input class="text-bold" formControlName="lpa" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="finalNetWeight" readonly tabindex="-1"></td>
            <td><input formControlName="numberOfBoilers" readonly tabindex="-1"></td>
            <td><input formControlName="hours" readonly tabindex="-1"></td>
            <td><input formControlName="days" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="efk" readonly tabindex="-1"></td>
            <td class="noPrint application" (click)="openApplication(i)"><mat-icon>picture_as_pdf</mat-icon></td>
            <td class="noPrint"><add-del-buttons [tabindex]="0" [bypassFormDisabled]="true" [hideAdd]="hideAdd()"
                    [hideDelete]="hideDelete()" (addButton)="addProducer()"
                    (deleteButton)="deleteProducer(i)"></add-del-buttons></td>
        </tr>
    </tbody>

    <tfoot>
        <tr [formGroup]="tsipouraTotals">
            <td colspan="4" class="text-right">ΣΥΝΟΛΑ: </td>
            <td><input class="text-bold" formControlName="totalWineGrapes" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalNonWineGrapes" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalGrapeStems" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalLpa" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalFinalNetWeight" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalNumberOfBoilers" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalHours" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalDays" readonly tabindex="-1"></td>
            <td><input class="text-bold" formControlName="totalEfk" readonly tabindex="-1"></td>
            <td class="noPrint"></td>
            <td class="noPrint"></td>
        </tr>
    </tfoot>
</table>



<table class="printOnly tsipouraCalculationData">
    <colgroup>
        <col style="width: 2%;" />
        <col style="width: 12%;" />
        <col style="width: 5%;" />
        <col style="width: 4%;" />
        <col style="width: 6%;" />
        <col style="width: 6%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 5%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 4%;" />
        <col style="width: 6%;" />
    </colgroup>
    <thead>
        <tr>
            <th>Α.Α.</th>
            <th>ΠΑΡΑΓΩΓΟΣ</th>
            <th>ΑΦΜ</th>
            <th>ΑΛΚΟΛΙΚΟΣ ΤΙΤΛΟΣ %/vol.</th>
            <th>ΣΤΑΦΥΛΙΑ ΟΙΝΟΠΟΙΗΣΙΜΑ (Kg)</th>
            <th>ΣΤΑΦΥΛΙΑ MH ΟΙΝΟΠΟΙΗΣΙΜΑ (Kg)</th>
            <th>ΣΤΕΜΦΥΛΑ</th>
            <th>Λ.Α. ΑΙΘΥΛΙΚΗΣ ΑΛΚΟΟΛΗΣ</th>
            <th>Λ.Ε. ΤΕΛΙΚΟ ΠΡΟΙΟΝ</th>
            <th>ΚΑΖΑΝΙΕΣ</th>
            <th>ΩΡΕΣ ΕΡΓΑΣΙΑΣ</th>
            <th>ΗΜΕΡΕΣ ΕΡΓΑΣΙΑΣ</th>
            <th>ΦΟΡΟΣ ΟΙΝΟΠΝΕΥΜΑΤΟΣ</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let item of tsipouraForm.get('tsipouraCalculationData').value; let i = index">
            <td>{{i+1}}</td>
            <td class=" text-bold">{{item.producerName}}</td>
            <td class=" text-bold">{{item.producerAfm}}</td>
            <td class=" text-bold">{{item.alcoholVol * 100}}%</td>
            <td class=" text-bold">{{item.wineGrapes}}</td>
            <td class=" text-bold">{{item.nonWineGrapes}}</td>
            <td class=" text-bold">{{item.grapeStems}}</td>
            <td class=" text-bold">{{item.lpa}}</td>
            <td class=" text-bold">{{item.finalNetWeight}}</td>
            <td>{{item.numberOfBoilers}}</td>
            <td>{{item.hours}}</td>
            <td>{{item.days}}</td>
            <td class=" text-bold">{{item.efk}}</td>
    </tbody>

    <tfoot>
        <tr>
            <td colspan="4" class="text-right">ΣΥΝΟΛΑ: </td>
            <td class="text-bold">{{tsipouraTotals.get('totalWineGrapes').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalNonWineGrapes').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalGrapeStems').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalLpa').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalFinalNetWeight').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalNumberOfBoilers').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalHours').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalDays').value}}</td>
            <td class="text-bold">{{tsipouraTotals.get('totalEfk').value}}</td>
        </tr>
    </tfoot>
</table>