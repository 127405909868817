import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DistillationLicenceAppFormModel } from 'app/main/apps/general-applications/distillation-licence-app/_models/distillation-licence-app-form.model';
import { DistillationLicenceAppModel } from 'app/main/apps/general-applications/distillation-licence-app/_models/distillation-licence-app.model';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable()
export class DistillationLicenceAppService {
    dilosiForm: BehaviorSubject<FormGroup<DistillationLicenceAppFormModel>>
    dilosiForm$: Observable<FormGroup<DistillationLicenceAppFormModel>>


    constructor() {


        this.dilosiForm = new BehaviorSubject(new FormGroup(new DistillationLicenceAppFormModel(new DistillationLicenceAppModel(this.defaults()))));

        this.dilosiForm$ = this.dilosiForm.asObservable();
    }


    defaults() {
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;

        const currentPeriod = currentYear + '/' + nextYear;
        const todaysDate = new Date();
        const formattedDate = (todaysDate.getDate() + '/' + (todaysDate.getMonth() + 1) + '/' + todaysDate.getFullYear()).toString();

        const defaultFormValue = new DistillationLicenceAppModel();

        defaultFormValue.period = currentPeriod;
        defaultFormValue.date = formattedDate;

        return defaultFormValue;
    }



    patchBoilerOwnerOnDilosi(tsipouraForm: FormGroup, index: number) {

        const dilosiForm = this.dilosiForm.getValue();

        const boilerOwner = tsipouraForm.get('boilerOwner').value || null;
        const agm = tsipouraForm.get('agm').value || null;
        const boilerCapacity = tsipouraForm.get('boilerCapacity').value || null
        const boilerInfo = dilosiForm.get('boilerInfo') as FormGroup;

        boilerInfo.get('boilerOwnerName').setValue(boilerOwner?.fullname);
        boilerInfo.get('boilerOwnerId').setValue(boilerOwner?.afm);
        boilerInfo.get('boilerNumber').setValue(agm);
        boilerInfo.get('boilerCapacity').setValue(boilerCapacity);
    }

    patchProducerOnDilosi(tsipouraForm: FormGroup, index: number) {

        const dilosiForm = this.dilosiForm.getValue();

        const calculationData = tsipouraForm.get('tsipouraCalculationData') as FormArray;
        const producer = calculationData.at(index) as FormGroup;


        const producerName = producer.get('producerName').value || null;
        const producerAfm = producer.get('producerAfm').value || null;

        const producerDetails = dilosiForm.get('producerDetails') as FormGroup;
        producerDetails.get('producerName').setValue(producerName);
        producerDetails.get('producerId').setValue(producerAfm);

    }


    patchRestOfDilosi(tsipouraForm: FormGroup, index: number) {

        const dilosiForm = this.dilosiForm.getValue();

        const calculationData = tsipouraForm.get('tsipouraCalculationData') as FormArray;
        const producer = calculationData.at(index) as FormGroup;

        const alcoholVol = (+producer.get('alcoholVol').value * 100).toString() || null;
        const wineGrapes = producer.get('wineGrapes').value || null;
        const nonWineGrapes = producer.get('nonWineGrapes').value || null;
        const grapeStems = producer.get('grapeStems').value || null;
        const lpa = producer.get('lpa').value || null;
        const finalNetWeight = producer.get('finalNetWeight').value || null;
        const startDateTime: Date = producer.get('startDateTime').value || null;
        const endDateTime: Date = producer.get('endDateTime').value || null;

        console.log("patchDilosi START DATE TIME: ", startDateTime)
        console.log("patchDilosi END DATE TIME: ", endDateTime)
        console.log("patchDilosi tsipouraForm: ", tsipouraForm.value)

        //get date as dd/MM/yyyy and time as HH:mm from startDateTime and endDateTime
        const startHours = `${startDateTime?.getHours().toString().padStart(2, '0')}:${startDateTime?.getMinutes().toString().padStart(2, '0')}`;
        const startDate = startDateTime?.getDate() + '/' + (startDateTime?.getMonth() + 1) + '/' + startDateTime?.getFullYear();
        const endHours = `${endDateTime?.getHours().toString().padStart(2, '0')}:${endDateTime?.getMinutes().toString().padStart(2, '0')}`;
        const endDate = endDateTime?.getDate() + '/' + (endDateTime?.getMonth() + 1) + '/' + endDateTime?.getFullYear();

        const grapes = dilosiForm.get('grapes') as FormGroup;
        const ownStems = dilosiForm.get('ownStems') as FormGroup;
        const returnedStems = dilosiForm.get('returnedStems') as FormGroup;


        if (wineGrapes) {
            dilosiForm.get('otherRawMaterials').setValue('Σταφύλια')
            grapes.get('quantity').setValue(wineGrapes);
            grapes.get('litresPureAlcohol').setValue(lpa);
            grapes.get('litresGross').setValue(finalNetWeight)
            grapes.get('alcoholicTitle').setValue(alcoholVol + '%')
        }
        else if (grapeStems) {

            returnedStems.get('quantity').setValue(grapeStems);
            returnedStems.get('litresPureAlcohol').setValue(lpa);
            returnedStems.get('litresGross').setValue(finalNetWeight)
            returnedStems.get('alcoholicTitle').setValue(alcoholVol + '%')
        }



        dilosiForm.get('startHours').setValue(startHours);
        dilosiForm.get('startDate').setValue(startDate);
        dilosiForm.get('endHours').setValue(endHours);
        dilosiForm.get('endDate').setValue(endDate);

        dilosiForm.get('unsealDate').setValue(startDate)
    }

    getPostData() {
        const dilosiForm = this.dilosiForm.getValue();

        console.log("saveDilosi dilosiForm: ", dilosiForm.value)

        const postData = {
            applicationForm: dilosiForm.getRawValue(),
            applicationType: 'distillersApp',
            customerAfm: dilosiForm.get('boilerInfo').get('boilerOwnerId').value,
        }


        return postData;


    }

    loadSavedApplication(dilosiModel: DistillationLicenceAppModel) {

        const savedForm = new FormGroup(new DistillationLicenceAppFormModel(new DistillationLicenceAppModel(dilosiModel)))

        this.dilosiForm.next(savedForm)
    }
}
